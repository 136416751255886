<template>
  <div>
    <a-row gutter="16">
      <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
        <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
      </a-col>
      <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
        <client-select v-model="searchForm.client" placeholder="客户" @change="search"></client-select>
      </a-col>
      <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
        <a-select v-model="searchForm.is_completed" placeholder="状态" allowClear style="width: 100%" @change="search">
          <a-select-option key="0" :value="false">进行中</a-select-option>
          <a-select-option key="1" :value="true">已完成</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
        <a-input v-model="searchForm.search" placeholder="单据编号, 客户单号, 备注" allowClear @pressEnter="search" />
      </a-col>
      <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
        <a-button type="primary" icon="search" @click="search">查询</a-button>
      </a-col>

      <a-col :span="24" :md="8" :xl="6" style="margin-bottom: 12px">
        <a-button icon="download" @click="exportExcel">导出</a-button>
      </a-col>
    </a-row>

    <div>
      <a-table
        rowKey="id"
        size="small"
        :columns="columns"
        :data-source="items"
        :pagination="pagination"
        :loading="loading"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :scroll="{ x: 1560, y: 480 }"
        @change="onChangeTable"
      >
        <template slot="number" slot-scope="value, item, index">
          <a @click="handleDetail(item.id)">{{ value }}</a>
        </template>
        <div slot="action" slot-scope="value, item, index">
          <a-button-group size="small">
            <a-button @click="handleDetail(item.id)">详情</a-button>
            <a-button v-if="!item.is_completed" type="primary" @click="quickDelivery(item)">普通复核</a-button>
            <a-button v-if="!item.is_completed" type="primary" @click="delivery(item)">序列号复核</a-button>
            <!-- <a-popconfirm title="确定完成吗?" @confirm="complete(item)">
              <a-button v-if="!item.is_completed" type="primary">完成</a-button>
            </a-popconfirm> -->
            <!-- <a-popconfirm title="确定作废吗?" @confirm="voidItem(item)">
              <a-button type="danger">作废</a-button>
            </a-popconfirm> -->
          </a-button-group>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
// import { stockOutOrderOption } from '@/api/option';
import { exportExcel } from "@/utils/excel";
import { deliveryOrdersExport } from "@/api/export";
import { deliveryTaskList, deliveryOrderComplete } from "@/api/stockOut";
import { columns } from "./columns";
import moment from 'moment'

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
  },
  data() {
    return {
      columns,
      pagination: { current: 1, total: 0, pageSize: 48, showTotal: (total) => `共 ${total} 条` },
      searchForm: { page: 1, page_size: 48, is_completed: false, is_void: false },
      loading: false,
      items: [],
      selectedRowKeys: [],
      selectionRows: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();
    },
    handleDetail(id) {
      this.$router.push({ path: "/stock_out/delivery_task_detail", query: { id } });
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      let searchForm = {...this.searchForm};
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      deliveryTaskList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // voidItem(item) {
      // stockOutOrderVoid({ id: item.id }).then(() => {
      //   this.$message.success("作废成功");
      //   this.list();
      // });
    // },
    complete(item) {
      deliveryOrderComplete({ id: item.id }).then(() => {
        this.$message.success("完成成功");
        this.list();
      });
    },
    delivery(item) {
      this.$router.push({ path: "/stock_out/delivery_record_create", query: { id: item.id } });
    },
    quickDelivery(item) {
      this.$router.push({ path: "/stock_out/quick_delivery_record", query: { id: item.id } });
    },
    detail(item) {
      this.$router.push({ path: "/stock_out/stock_out_order_detail", query: { id: item.id } });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
    },
    exportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要导出的出库通知单!");
        return false;
      }
      deliveryOrdersExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, "出库通知单列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
