export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "出库通知单号",
    dataIndex: "number",
    sorter: true,
    fixed: "left",
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: "number" },
  },
  {
    title: "客户单号",
    dataIndex: "external_number",
    width: 120,
    ellipsis: true,
  },
  {
    title: "应复核数量",
    dataIndex: "total_quantity",
    width: 100,
    ellipsis: true,
  },
  {
    title: "剩余复核数量",
    dataIndex: "remain_quantity",
    width: 100,
    ellipsis: true,
  },
  {
    title: "状态",
    dataIndex: "status",
    width: 100,
    ellipsis: true,
    customRender: (value, item, index) => {
      return item.is_completed ? "已完成" : "进行中";
    },
  },
  {
    title: "订单类型",
    dataIndex: "order_type_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: "客户",
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "备注",
    dataIndex: "remark",
    width: 240,
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    fixed: "right",
    width: 240,
    scopedSlots: { customRender: "action" },
  },
];
